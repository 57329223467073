.search-bar {
	position: relative;
	width: 95%;
}

input {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}

.results-list {
	position: absolute;
	top: 40px;
	width: 100%;
	max-height: 200px;
	overflow-y: auto;
	background: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.results-list li {
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	transition: background 0.3s;
}

.results-list li:hover {
	background: #f0f0f0;
}

.results-list img {
	margin-right: 10px;
}

.selected-product {
	margin-top: 20px;
	text-align: left;
	background: #f9f9f9;
	padding: 10px;
	border-radius: 4px;
}

.selected-product img {
	margin-bottom: 10px;
}
